import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  methods: {
    popupError (message) {
      this.$swal({
        title: 'Lỗi!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Xác nhận',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },

    toastError (message, variant = 'danger', icon = 'XCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('axios.title.error'),
          icon: icon,
          variant: variant,
          text: message
        }
      })
    }
  }
}
