<template>
  <div>
    <span v-if="label !== undefined">{{ label }}</span>
    <b-alert
        class="pt-2 pb-1 mt-1"
        style="max-height: 400px; overflow-y: auto;"
        variant="primary"
        show
    >
      <ol>
        <li v-for="(item, index) in items" :key="index">
          <span class="font-weight-bolder font-medium-2">
            <slot name="item-label" :data="item"/>
          </span>
        </li>
      </ol>
    </b-alert>
  </div>
</template>

<script>

export default {
  name: 'ItemList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: undefined
    },
  }
}
</script>
